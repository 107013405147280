@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* 
font-family: 'PT Serif', serif;
font-family: 'Poppins', sans-serif;
font-family: 'Orbitron', sans-serif;
*/

:root {
  --brown: #b29547;
  --darkbrown: #92793a;
  --black: #000000;
  --white: #ffffff;
  --lightblue: #3aa9c1;
  --darkblue: #0d6efd;
  --success: #55ab68;
  --hoversuccess: #3a7648;
  --red: #c12300;
  --darkred: #ff0000;
}

body {
  /* background-image: url(../img/bg.svg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  font-family: 'Poppins', sans-serif;
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  overflow-x: hidden;
  background: #fff5ec;
}

a {
  color: var(--brown);
}

a:hover {
  color: var(--darkbrown);
}

p {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  font-family: 'PT Serif', serif;
}

input,
select {
  border-color: #4f4f4f !important;
  box-shadow: 0 2px 10px #777;
}

.mainContainer {
  width: 100%;
  float: left;
  /* margin-top: 550px; */
  overflow-x: hidden;
}



/* custom buttons */

.custom_btn {
  text-align: center;
  color: #fff;
  outline: none;
  box-shadow: 0 0 15px #ccc;
  padding: 8px 12px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.custom_btn:hover,
.custom_btn:focus {
  box-shadow: none;
}

.brown_btn {
  background: var(--brown);
  border-color: var(--brown);
}

.brown_btn:hover,
.brown_btn:focus {
  background: var(--darkbrown);
  border-color: var(--darkbrown);
}

.save_btn {
  background: var(--lightblue);
  border-color: var(--lightblue);
}

.save_btn:hover,
.save_btn:focus {
  background: var(--darkblue);
  border-color: var(--darkblue);
}

.cancel_btn,
.cancel_btn:hover,
.cancel_btn:focus {
  background: transparent;
  color: #000;
  border: 0;
  box-shadow: none;
}

.success_btn {
  background: var(--success);
  border-color: var(--success);
}

.success_btn:hover,
.success_btn:focus {
  background: var(--hoversuccess);
  border-color: var(--hoversuccess);
}

.remove_btn {
  background: var(--red);
  border-color: var(--red);
}

.remove_btn:hover,
.remove_btn:focus {
  background: var(--darkred);
  border-color: var(--darkred);
}

.big_btn {
  padding: 10px;
}

.white_border_btn {
  border: 1px solid #000;
  /* background: transparent; */
  background: white; 
  color: #000;
  box-shadow: none;
}

.white_border_btn:hover,
.white_border_btn:focus {
  background: var(--brown) !important;
  border-color: var(--brown);
}

.brown_border_btn {
  border: 1px solid #b29547;
  background: transparent;
  color: #b29547;
  box-shadow: none;
}

.brown_border_btn:hover,
.brown_border_btn:focus {
  background: var(--brown);
  border-color: var(--brown);
}

/* header css */

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 35px;
  background-color: var(--blue);
}

.navlinks {
  justify-content: flex-end;
  width: 100%;
}

/* body .header .nav-link {
  color: var(--black) !important;
} */

.cstom-dropdown .dropdown-menu {
  left: -50px;
  padding: 0;
  min-width: 9rem;
  border: 1px solid #eee;
}

.cstom-dropdown .dropdown-menu .dropdown-divider {
  margin: 0;
}

.logo {
  max-width: 200px;
}

/* homepage header css */

.homepage .header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 123;
  background: #161006;
  /* background: rgba(33 ,14 ,10 ,0.8); */
  /* display: none; */
  min-height: 70px;
}

.main .header {
  display: flex !important;
}

/* .main.homepage .header{
  display: none !important;
} */

.main.homepage .header.is-sticky {
  display: flex !important;

}

.homepage .header.is-sticky{ 
  z-index: 99;
}
.header.is-sticky{
  position: fixed;
  display: flex !important;
  background: #161006;
  /* background: rgba(33 ,14 ,10 ,0.8); */
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.header .text-black .nav-link {
  color: var(--black) !important;
}

.text-white .nav-link {
  color: var(--white) !important;
}

.header .nav-link {
  color: var(--white) !important;
}


.header.is-sticky .text-black .nav-link {
  color: var(--white) !important;
}



/* login page css */

.loginDiv {
  width: 40%;
  margin: 60px auto;
}

.loginDiv h2 {
  text-align: center;
  margin-bottom: 30px;
}

.form-floating {
  width: 100%;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: 2.9rem;
  padding: 7px 10px !important;
  border-color: #4f4f4f;
  box-shadow: 0 2px 10px #777;
  font-size: 14px;
  font-weight: 500;
}

.form-floating > .form-control {
  background: #fff;
}

.form-floating > label {
  padding: 0.7rem;
}

.loginDiv h6 {
  text-shadow: 0 0px 10px #777;
  margin-bottom: 20px;
}

.warningTxt {
  font-size: 12px;
}

.signupTxt {
  text-align: center;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
  background: #fff;
  height: auto;
  padding: 0 6px;
  width: auto;
  left: 10px;
}

.btn-Link {
  padding: 0;
  line-height: 1;
  color: var(--brown);
  font-size: 14px;
  font-weight: 500;
}

/* sidebar css */

.sidebar {
  background-color: #161006;
  width: 160px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
}

.sidebar .nav-item .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 5px;
  text-align: center;
  border-left: 4px solid transparent;
  transition: all 0.4s ease-in-out;
}

.sidebar .nav-item .nav-link.active,
.sidebar .nav-item .nav-link:hover {
  background: var(--brown);
  border-left: 4px solid #fff;
}

.sidebar_logo {
  padding: 48px 5px;
}

.sidebar_logo .logo {
  max-width: 140px;
}

.sidebar span {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin: 3px 0;
}

.sidebar span svg {
  font-size: 20px;
}


.homepage {
  padding-top: 70px;
}

.main {
  padding-left: 160px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  padding-top: 70px;
}

.main.homepage {
  padding-left: 0;
}

.rightsidebar {
  width: 100%;
  display: inline-block;
}

.rightsidebar .navbar-brand {
  display: none;
}

.main.homepage .rightsidebar .navbar-brand {
  display: block;
}

.nominee_workflow {
  padding-left: 70px;
  width: 100%;
  padding-right: 20px;
  padding-bottom: 20px;
  position: relative;
}

.inner_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
  position: relative;
}

.inner_title h6{
  font-family: 'Poppins', sans-serif;
}

.inner_title h2 {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
}

.nominee_workflow h4 {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
}

.inner_title svg {
  cursor: pointer;
}


.btn-primary.disabled, .btn-primary:disabled {
    color: #000;
    background-color: #cccccc;
    border-color: #cccccc;
}

.personal_details {
  width: 40%;
  margin: 0 auto;
}

.user_img {
  margin-bottom: 20px;
  position: relative;
}

.img_icon {
  width: 100px;
  border-radius: 50px;
}

.user_img svg {
  position: absolute;
  top: 0;
  cursor: pointer;
}

.custom_toaster {
  position: absolute;
  right: 0;
  top: 40px;
  width: auto;
  border-radius: 15px;
  z-index: 2;
}

.notify_icon {
  font-size: 24px;
}

.toast-body p:last-child {
  margin: 0;
}

.toast-header .btn-close {
  position: absolute;
  right: 15px;
}

.toast-header {
  background: transparent;
}

.table-responsive {
  position: relative;
}

.cart_details1 {
  position: relative;
}

/* add nominations css */

.cart_details {
  width: 100%;
  display: inline-block;
}

.tr_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--black);
  box-shadow: 0 0 15px #ddd;
  margin-bottom: 20px;
}

.td_col {
  text-align: left;
  padding: 20px 10px;
  color: var(--black);
  font-weight: 400;
  width: 100%;
}

.tr_header {
  background-color: #210e0a;
}

.bg_dark_brown {
  background-color: #210e0a !important;
  border-color: #210e0a !important;
}

.tr_header .td_col {
  color: #fff;
  font-weight: bold;
}

.close_icon svg {
  color: #c53110;
  cursor: pointer;
}

.eye_icon svg {
  color: #b29547;
  font-size: 21px;
}

/* nominated page */

.nominated_page .td_col:last-child {
  text-align: center;
}

/* cart page */

/* custom scrollbar */

/* width */
.cart_inner::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.cart_inner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.cart_inner::-webkit-scrollbar-thumb {
  background: #767676;
  border-radius: 10px;
}

/* Handle on hover */
.cart_inner::-webkit-scrollbar-thumb:hover {
  background: #222222;
}

.cart_inner {
  max-height: 300px;
  overflow: auto;
  padding: 10px 20px 20px;
  margin-bottom: 20px;
}

.cart_row {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.cart_table {
  border: 1px solid #000;
  padding: 20px 20px 0;
  border-radius: 5px;
  box-shadow: 0 2px 10px #777;
}

.cart-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.cart_content p,
.cart_content h6 {
  margin-bottom: 0;
}

.cut svg {
  font-size: 25px;
  cursor: pointer;
}
.edit svg {
  font-size: 20px;
  cursor: pointer;
}
.back svg {
  font-size: 22px;
  cursor: pointer;
}

.billing p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.billing p span {
  text-align: right;
}
.referal_input {
  display: flex;
  justify-content: space-between;
}

/* .custom_file input{
  background: transparent;
  border: 0;
  box-shadow: none;
  text-align: center;

} */

.referal_input .form-floating input {
  box-shadow: none;
}
.referal_input .custom_btn {
  padding: 10px 12px;
}

.cart_table .table td {
  border: 0;
}

/* questionnaire page css starts from here */

.questionnaire_main {
  width: 100%;
  padding: 20px;
}

.title_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.title_div h4{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;

}

.title_div h6 {
  margin-right: 80px;
}

.inner_form {
  width: 100%;
  margin-bottom: 20px;
}

.tab_bar {
  display: inline-flex;
  border: 0;
  justify-content: space-between;
  width: 100%;
}

.tab_bar li.nav-item {
  width: 25%;
}

.tab_inner_div {
  width: 60%;
  margin: 0 auto;
}

.tab_bar .nav-link {
  padding: 10px;
  color: #7e7e7e;
  font-weight: 600;
  font-size: 15px;
  border-bottom: 3px solid #7e7e7e;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  width: 100%;
}

.tab_bar.nav-tabs .nav-link.disabled {
  border-bottom: 3px solid #7e7e7e;
}

.tab_bar .nav-link.active,
.tab_bar .nav-link:hover {
  border-bottom: 3px solid #b29547;
  border-right: 0;
  color: #b29547;
}

.form_row {
  width: 100%;
  margin-bottom: 25px;
}
.form_row_last{
  text-align: center;
}

.form_row .form-label {
  font-size: 15px;
}

.form-check-input {
  width: 1.2rem;
  height: 1.2rem;
  box-shadow: none;
  margin-right: 10px;
}

.form-check-input[type='checkbox'] {
  border-radius: 0;
}

.form-check-input:checked {
  background-color: #b29547;
  border-color: #b29547;
}

.form_row .form-check {
  padding-left: 40px;
}

.form_row .pd-basic {
  padding-left: 1.5em;
}

.radio_input {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.radio_input .form-check {
  min-width: 190px;
}

.radio_input input[type='text'] {
  border-left: 0;
  box-shadow: none;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  margin-left: 40px;
}

span.required {
  color: red;
}

.form_btns {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
}

/*====homepage css starts here=== */

#mainId {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.height-max-content {
  height: max-content;
}

section {
  width: 100%;
  float: left;
  padding: 70px 0;
}

.h_100vh {
  height: 100vh;
}

.extra_spacing {
  padding-bottom: 100px;
}

.aboutSection {
  width: 100%;
}

.d-flex-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img.scrollDown {
  position: absolute;
  top: 75vh;
  left: 38px;
  right: 0;
  margin: 0 auto;
  max-width: 70px;
}

/* banner section starts  */
.home_section {
  position: relative;
  background: url(../../assets/img/confetti.png) #210e0a;
}

.bannerContent {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 20px 0;
}

.leaf_img {
  max-width: 565px;
  margin: 0 auto;
}

.bannerHeading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.textHeading {
  font-size: 50px;
  color: #ffffff;
  font-weight: 600;
  font-family: 'PT Serif', serif;
}

.text2021 {
  color: #f0bf43;
  font-size: 50px;
  font-weight: 600;
  margin: 0;
  font-family: 'PT Serif', serif;
}

.videoSec video {
  /* padding-top: 50px; */
  height: 90%;
}

/* winner section starts */

.section_title {
  width: 100%;
  padding: 0 35px;
  margin-bottom: 30px;
}

.section_title h2 {
  font-family: 'PT Serif';
  font-size: 35px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.winner_year {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.year {
  margin: 5px;
  height: 150px;
  width: 100px;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(15deg);
  z-index: 1;
  margin-left: -20px;
  cursor: pointer;
}
.year span {
  font-size: 50px;
  color: #fff;
  font-family: 'PT Serif';
  line-height: 1;
  font-weight: bold;
}

.year.active {
  transform: rotate(0deg);
  z-index: 2;
  margin: 0;
  width: 130px;
  height: 200px;
}

.bg_green {
  background: #2a9d8e;
}

.bg_orange {
  background: #f3a261;
}

/* benefit section */

.imgcards {
  text-align: center;
}

.imgcards h3 {
  font-size: 20px;
  margin-top: 15px;
}

.row_top {
  position: relative;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row_top > * {
  position: absolute;
  margin: 5px;
}

.rect1 {
  width: 170px;
  height: 50px;
  background: #f1ccb1;
  border-radius: 50px;
  transform: rotate(-25deg);
  top: 45px;
  left: 2%;
}

.rect2 {
  width: 140px;
  height: 50px;
  background: #b65f20;
  border-radius: 50px;
  left: 17%;
}

.rect3 {
  width: 130px;
  height: 50px;
  background: #d97126;
  border-radius: 50px;
  transform: rotate(30deg);
  top: 60px;
  left: 31%;
}

.rect4 {
  width: 130px;
  height: 50px;
  background: #df8749;
  border-radius: 50px;
  left: 38%;
}

.rect5 {
  width: 150px;
  height: 50px;
  background: #e3955f;
  border-radius: 50px;
  transform: rotate(20deg);
  left: 51%;
  top: 35px;
}

.rect6 {
  width: 140px;
  height: 50px;
  background: #b65f20;
  border-radius: 50px;
  left: 63%;
}

.rect7 {
  width: 70px;
  height: 50px;
  background: #df8749;
  border-radius: 50px;
  left: 76%;
}

.rect8 {
  width: 160px;
  height: 50px;
  background: #e3955f;
  border-radius: 50px;
  transform: rotate(35deg);
  left: 83%;
  top: 45px;
}

.rect9 {
  width: 150px;
  height: 50px;
  background: #ebb58e;
  border-radius: 50px;
  left: 15%;
  top: 83px;
}

/* about sec  */

.bg-brown {
  /* background: url(../../assets/img/confetti.png) #210e0a; */
  background: #161006;

}

.bg-white {
  background: #ffffff;
}

.inner_about {
  padding: 70px 0;
}

.inner_about {
  width: 100%;
  height: max-content !important;

}

.about_txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.about_txt p {
  font-weight: 400;
  /* width: 650px; */
  text-align: justify;
  line-height: 1.8;
  font-size: 16px;
  color: #000;
}

.about_width_63{
  width: 63.666667%;
}

.right_bottom_about_text > h3 , .right_bottom_about_text > p {
  position: initial;
  left:-150px;
}

.about_img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  position: relative;
  /* padding-bottom: 20px; */
}

.tp_to_bttm img {
  animation: 2s ease-in-out  alternate swingParent;
  animation-iteration-count: 2;
}

.bttm_to_tp img {
  animation: 2s ease-in-out  alternate swingParent1;
  animation-iteration-count: 2;
  animation-delay: 0.3s;
}

@keyframes swingParent {
  from {
    transform: translateX(0%) translateY(0);
  }
  to {
    transform: translateX(-190%) translateY(100%);
  }
}

@keyframes swingParent1 {
  from {
    transform: translateY(0%) translateX(-0%);
  }
  to {
    transform: translateY(-100%) translateX(190%);
  }
}

.pd-ryt {
  padding-right: 100px;
}

.pd-lft {
  padding-left: 100px;
}

/* cateogoeis css */

.category_section {
  position: relative;
  overflow: hidden;
  padding-bottom: 190px;
}

.category_section::before {
  content: '';
  background: #b17c1c;
  display: inline-block;
  width: 1000px;
  height: 1000px;
  position: absolute;
  right: -5%;
  top: -30%;
  border-radius: 50%;
}

.caterogy_inner {
  position: relative;
  padding-left: 80px;
}

.categories {
  text-align: center;
  position: relative;
}

.ellipse_dotted {
  width: 100%;
  z-index: 1;
}

.MainLogo2021 {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* jury Slider */

.jurySlider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
}

.jurySlide {
  width: 100%;
  background: #fff;
  text-align: center;
  position: relative;
  border-radius: 10px;
  margin: 10px;
}

.jury_img {
  width: 100%;
  border-radius: 10px;
}

.jury_name {
  background: #000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  transform: rotate(-90deg);
  left: -32px;
  margin: 0;
  bottom: 95px;
}

.product_logo {
  padding: 10px 0;
  height: 60px;
  object-fit: contain;
}

/* partners sec */

.partner_logos {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
}

.partner {
  width: 100%;
  display: inline-block;
}

.brand_logo h5 {
  color: var(--white);
}

.brand_logo img {
  max-width: 180px;
  background: #fff;
  padding: 20px 10px;
  border-radius: 7px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-70 {
  margin-top: 70px;
}

/* footer section */

.footer_section {
  overflow: hidden;
  padding: 60px 0;
}


.footer_section .container-fluid {
  padding: 0;
}

.footer_section p a,
.footer_section h3,
.footer_links li a {
  color: #fff;
  text-decoration: none;
}

.footer_text p , .footer_text h4 {
  color: #fff;
}

.footer_text p {
  line-height: 2;
}

.footer_text h3, .social_connect h4 {
  color: #F8CF7E;
  font-size: 33px;
  font-weight: bold;
}

.footer_text h4 {
  font-weight: bold;
}

.bg_light_orange {
  background: #fff5ec;
}

.footer_form {
  width: 450px;
  height: 450px;
  background: #fbe1af;
  border-radius: 100%;
  padding: 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.form_inner {
  width: 80%;
  margin: 15px auto 0;
}

.form_inner .form-floating>.form-control {
    background: #fff;
    min-height: auto;
}

.footer_form_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  /* padding: 70px 0; */
  position: relative;
}

img.rotataImg {
  width: 75%;
  animation: spin 20s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bdr_btm {
  border-bottom-right-radius: 49%;
}

.footer_details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 0px 0 60px;
  height: 50%;
  align-items: center;
}

.footer_links {
  margin: 55px 0px 0 70px;
}

.footer_links li {
  list-style: none;
  margin-bottom: 15px;
  font-size: 16px;
}

.footer_bottom {
  padding: 0px 0 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
}

.social_links {
  margin: 50px 0 60px;
}

.social_links ul {
  padding: 0;
}

.social_links li {
  display: inline-block;
  list-style: none;
  margin-right: 20px;
}

.social_links a {
  color: #000;
  padding-right: 20px;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400 !important;
}

.social_links li img {
  max-height: 20px;
  width: auto;
  margin-right: 5px;
}

.social_links ul li a {
  color: #fff;
}



.footer_bottom a {
  color: #FAB900;
  font-weight: 600;
}

.social_connect p {
  color: #fff;
}



.social_connect p a {
  margin-left: 15px;
  color: #fff;
  font-weight: 600;
}

.text-facebook {
  color: #1877F2 !important;
}
 
.text-instagram {
  color: #EA4967 !important;
}
 
.text-linkedin {
  color: #0A66C2 !important;
}
 
.text-channelier {
  color: #3CE396 !important;
}

.submitDiv span {
  background: #da9020;
  padding: 12px 15px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.submitDiv {
  display: inline-block;
  margin-top: 15px;
  width: 100%;
  background: transparent;
  border: 0;
}

.submitDiv span:hover svg {
  color: white;
}
.submitDiv span:hover {
  background: #210e0a;
}

.homeModal .modal-dialog {
  max-width: 60%;
}

.homeModal .modal-content {
  background-image: url(../img/2023/modal_bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 25px;
  border: 1px solid #fff;
  overflow: hidden;
}

.homeModal .modal-header {
  background: #FFC400;
  justify-content: center;
  height: 40px;
}

.homeModal .modal-header p {
  font-size: 25px;
  font-weight: 800;
  margin: 0;
}

.homeModal .btn-close {
  opacity: 1;
  position: absolute;
  right: 35px;
  border: 2px solid #000;
  border-radius: 50px;
}


.homeModal .custom_btn {
  padding: 5px 12px;
}

.nomination_modal {
  padding: 40px 20px;
}

.nomination_modal_catgry img {
  width: 60%;
}

.nomination_row {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  text-align: center;
}

.nomination_modal_catgry, .nomination_modal_content {
    width: 50%;
}

.nomination_modal_catgry h1 {
    color: #FFC400;
    font-weight: bold;
    font-size: 70px;
    line-height: 1;
    margin: 15px 0;
}

.nomination_modal_catgry p {
    text-transform: capitalize;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}

.nomination_modal_content .form_row {
  display: flex;
  width: 60%;
  margin: 0 auto;
}

.nomination_modal_content .form-control {
  border-radius: 0;
  border: 0;
}

.nomination_modal_content .custom_btn {
  background: #FFC400;
  border-color: #FFC400;
  border-radius: 0;
  box-shadow: none;
  margin-left: -2px;
  border: 0;
}

.nomination_modal_content .custom_btn img {
  max-width: 15px;
}


.nomination_modal_content h3 {
  color: #fff;
  margin-top: 50px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
}

.nomination_modal_catgry h3 {
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}

.nomination_date span {
  background: #777;
  color: #fff;
  font-size: 25px;
  padding: 0 8px;
  margin: 1px;
  font-weight: 400;
}

.nomination_modal .registerNow {
  position: relative;
  bottom: 0;
  margin-top: 40px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nomination_modal .registerNow .custom_btn {
  border-radius: 15px 0 0 0;
}

.detailPage {
  margin-top: 80px;
  height: 100%;
}

.detailPage .section-title h1 {
  margin-bottom: 2rem;
}

.detailPage h3 {
  margin-top: 20px;
}


#set-height {
  display: block;
  /* height: 5000px !important; */
}

#v0 {
  width: 100%;
  height: 100%;
}

/* #v0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  object-fit: cover;
}
   */

/* 3d testimonial section */

video.testimonial_video {
    width: 100%;
    height: auto;
    margin: 100px 0 0;
}

.testimonial_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.testimonial_box {
  position: relative;
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  animation: animate 20s linear infinite;
}

@keyframes animate {
  0% {
    transform: perspective(1500px) rotateY(0deg);
  }
  100% {
    transform: perspective(1500px)  rotateY(360deg);
  }
}

.testimonial_box .testi_video_div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--my-css-var)  * 60deg)) translateZ(400px);
}

.testimonial_box .testi_video_div video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.logo_img {
  width: 40%;
}


#set-height {
  display: block;
  height: 3000px !important;
}

#v0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  object-fit: cover;
}
  


.animate__flipInY {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes flipInY {
  0% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
      -webkit-transform:  rotateY(90deg);
      transform: perspective(400px) rotateY(90deg)
  }

  40% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: perspective(400px) rotateY(-20deg);
      transform: perspective(400px) rotateY(-20deg)
  }

  60% {
      opacity: 1;
      -webkit-transform: perspective(400px) rotateY(10deg);
      transform: perspective(400px) rotateY(10deg)
  }

  80% {
      -webkit-transform: perspective(400px) rotateY(-5deg);
      transform: perspective(400px) rotateY(-5deg)
  }

  to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px)
  }
}

@keyframes flipInY {
  0% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
      -webkit-transform: perspective(400px) rotateY(90deg);
      transform: perspective(400px) rotateY(90deg)
  }

  40% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: perspective(400px) rotateY(-20deg);
      transform: perspective(400px) rotateY(-20deg)
  }

  60% {
      opacity: 1;
      -webkit-transform: perspective(400px) rotateY(10deg);
      transform: perspective(400px) rotateY(10deg)
  }

  80% {
      -webkit-transform: perspective(400px) rotateY(-5deg);
      transform: perspective(400px) rotateY(-5deg)
  }

  to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px)
  }
}

.loader {
  width: 100%;
  height: 100vh !important;
  position: absolute;
  background: url(../img/webp/banner.webp);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader img {
  max-width: 100px;
}

/* .banner_slider {
  position: relative;
} */

.scrollArrow {
  position: absolute;
  right: 30px;
  bottom: 120px;
  color: #FAB900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  z-index: 1;
}

img.arrowIcon {
  max-width: 50px;
}

.mobileBanner, .mobilebgHeight {
  display: none;
}

.award2023 video {
  position: relative;
}


/* countdown css */


.countdown {
  padding: 100px 0;
  width: 100%;
  float: left;
}

#clockdiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

#clockdiv > div {
  margin-right: 20px;
  text-align: center;
}

#clockdiv div span {
  font-weight: 300;
  margin-right: 15px;
  /* background: linear-gradient(180deg, #FDD446 0%, rgba(255, 255, 255, 0.1) 100%); */
  background: url(../img/voting_bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  padding: 20px 10px;
  font-size: 80px;
  font-family: 'Orbitron', sans-serif;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  width: 90px;
  line-height: 1;
  text-align: center;
}

.smalltext {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 10px;
}

.countdown h4 {
  color: #FDD446;
  font-family: 'Poppins' , sans-serif;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 1px;
}

.votingSlider {
  width: 100%;
  margin-top: 60px;
}

.votingImg img {
  margin-bottom: 20px;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
}

.votingImg p {
  text-align: justify;
}

.votingSlider  h3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  margin-bottom: 50px;
  font-family: 'poppins' , sans-serif;
  font-size: 40px;
  align-items: center;
}

.votingSlider h3 a {
  color: #FDD446;
  font-size: 20px;
}

.votingSlide{
  background: #fff;
  height: 200px !important;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.votePage {
  padding: 70px 0 50px;
  width: 100%;
  height: 100%;
}

.votingImage {
  width: 100%;
  object-fit: contain;
  max-height: 130px;
}

.products {
  width: 100%;
  height: 500px;
  overflow: auto;
}

.productImg {
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 202px;
  height: 250px;
  margin: 60px 5px 5px;
  position: relative;
  cursor: pointer;
  padding: 50px 7px 7px;
  float: left;
  border-radius: 10px;
}

.brandLogoVote {
  background: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid #ddd;
  padding: 8px;
  box-shadow: 1px 1px 10px #ddd;
  position: absolute;
  top: -50px;
  z-index: 9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brandLogoVote img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 7px;
  border-radius: 10px;
  /* border-radius: 50px; */
}


.productImgVote img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.productImg span {
  text-align: center;
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;
  word-break: break-word;
}

.alreadyNominated {
  width: 100%;
  margin-bottom: 10px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
}

.alreadyNominated img {
  margin: 0 10px 0 0;
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.alreadyNominated span {
  font-weight: 700;
}

.productImg p {
  margin: 0;
  text-align: center;
  font-size: 13px;
}


.brandLogoVote p {
  font-size: 12px;
}


.activeVote::after {
  content: "";
  background: #000;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: absolute;
  border: 2px solid #000;
  top: 5px;
  right: 5px;
  padding: 7px;
  box-shadow: inset 0px 0px 0px 2px #fff;
}

.productImg.activeVote {
  opacity: 0.6;
}


.voteTitle h4 {
  color: #FDD446;
  font-family: 'Poppins' , sans-serif;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
  position: relative;
}

.voteTitle h4 img {
  position: absolute;
  left: 0;
  top: 15px;
  max-width: 15px;
}

.voteButton {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  border-top: 3px solid #FDD446;
  padding: 20px 0;
  background: url(../img/vg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}


.voteButton p {
  font-size: 25px;
  color: #FDD446;
}

.submitVote {
  background: transparent;
  box-shadow: none;
  color: #fff;
  border: 2px solid #FDD446;
  padding: 10px 22px;
  font-size: 17px;
  /* text-transform: uppercase; */
}

.temp-layout{
  margin-top: 93px;
}



/* Shutter Out Vertical */
.hvr-shutter-out-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hvr-shutter-out-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 3px;
  background: #BE8F2D;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-shutter-out-vertical:hover,
.hvr-shutter-out-vertical:focus,
.hvr-shutter-out-vertical:active {
  color: #ffffff !important;
  border: 2px solid #BE8F2D;
  border-radius: 5px;
}

.hvr-shutter-out-vertical:hover:before,
.hvr-shutter-out-vertical:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.hvr-shutter-out-vertical:focus:before {
  background:#BE8F2D;
}

.triangleShape::before {
  content: "";
  border: 2px solid #FDD446;
  position: absolute;
  width: 15px;
  height: 15px;
  top: -8px;
  transform: rotate(45deg);
  margin: 0 auto;
  left: 0;
  right: 0;
  background: #210E0A;
}

.triangleShape::after {
  content: "";
  border: 2px solid #FDD446;
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: -8px;
  transform: rotate(45deg);
  margin: 0 auto;
  left: 0;
  right: 0;
  background: #210E0A;
}

.submitVote.hvr-shutter-out-vertical:hover .triangleShape::after, .submitVote.hvr-shutter-out-vertical:hover .triangleShape::before {
  background: #ae1409;
  border-color: #BE8F2D;
}


.companyVoting {
  width: 100%;
  display: flex;
  min-height: 450px;
  overflow: auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.companyVote {
  background: #fff;
  border-radius: 15px;
  text-align: center;
  width: 350px;
  padding: 10px;
  float: left;
  margin: 10px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

/* .companyVote img {
  max-width: 100px;
} */

.companyVote p {
  margin: 5px 0;
}

.pductName {
  font-weight: 600;
}

.companyVoteBrand {
  position: absolute;
  left: 10px;
  top: 0;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.companyVotePrdct {
  height: 190px;
  width: 190px;
  object-fit: contain;
}

.companyVote.activeVote {
    opacity: 0.6;
}


.submitVoteModal {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: slideInUp;
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}


.submitVoteModal .modal-dialog {
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 99%;
}

.submitVoteModal .modal-content {
  background: transparent;
}

.votemodalBody {
  background-image: url(../img/confetti.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px;
  border-top: 3px solid #FDD446;
  margin-left: -5px;
}

.voterLogin {
  width: 35%;
  margin: 0 auto;
}

.voterLogin .form-floating > .form-control {
  background: transparent;
  box-shadow: none;
  border-color: #fff !important;
  color: #fff;
}

.voterLogin .form-floating  label {
  color: #fff;
}

.voterLogin .form-floating > .form-control:focus ~ label, .voterLogin .form-floating > .form-control:not(:placeholder-shown) ~ label, .voterLogin .form-floating > .form-select ~ label{
  color: #fff;
  background-color:  #210e0a;
}

.voterLogin .warningTxt {
  color: #fff;
}

.successVoteModal .modal-dialog {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.successVote {
  text-align:center;
}

.successVote img {
  max-width: 50px;
}

.successVote p {
  margin: 10px 0;
}

.voterLogin .btn-Link {
  color: #fff;
  border: 0;
  outline: 0;
  box-shadow: none;
}

.registerNow {
  position: fixed;
  right: 15px;
  bottom: 20px;
  z-index: 999;
  /* display: none; */
}

.registerNow button {
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFC400;
  color: #000;
  border-radius: 50px;
  box-shadow: none;
  padding: 8px 20px;
  font-family: 'PT Serif';
  font-size: 20px;
  font-weight: 700;
}

.registerNow img {
  max-width: 35px;
}

.arrow_register {
  background: #FCE7A2;
  border-radius: 50px;
  width: 50px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
  transition: all 0.2s ease-in-out;
  animation: arrow-move 1s infinite;
}

.arrow_register:hover {
  animation: none;
}

.arrow_register img {
  width: 15px;
}

@keyframes arrow-move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.RegisterTop {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.RegisterTop button img {
  max-width: 35px;
}

.RegisterTop button {
  border-color: transparent;
  border-radius: 50px !important;
  font-size: 27px;
  letter-spacing: 1px;
  font-weight: 500;
  text-shadow: 0 0 8px #333;
  padding: 8px 25px;
}

.RegisterTop .hvr-shutter-out-vertical:before {
   border-radius: 50px;
}

.registerShow {
  display: block;
}

.share {
  width: 100%;
  
 
}
.share ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.share ul li {
  list-style: none;
  display: flex;
  justify-content: center;
}
.share ul li a {
  width: 70%;
  text-decoration: none;
  display: block;
}

.share ul li a img {
  width: 100%;
  cursor: pointer;
  max-height: 60px;
}

/* start from here */

.votingShareContainer {
   width: 100%;
   position: relative;
   margin: 0 auto;
}

.votingbanner {
  width: 100%;
  border: 5px solid #b29547;
  border-radius: 10px;
}

.votebannerContent {
  position: absolute;
  bottom: 20%;
  left: 6%;
  text-align: center;
}

.votebannerContent img {
   max-width: 165px;
}

.votebannerContent h2 {
   /* background: -webkit-linear-gradient(#D7AC6B, #F4D187, #FFFFC2);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent; */
   color:#D7AC6B ;
   font-size: 32px;
   font-family: 'Montserrat', sans-serif;
   font-weight: 400;
   text-transform: uppercase;
   line-height: 1;
   margin: 0 0 10px;
}

.votebannerContent p {
   color: #fff;
   text-transform: uppercase;
   font-family: 'Montserrat', sans-serif;
   font-size: 18px;
   border-top: 1px solid #fff;
   border-bottom: 1px solid #fff;
   line-height: 1;
   padding: 10px 20px;
   display: inline-block;
}

.votebannerContent h1 {
   /* background: -webkit-linear-gradient(#D7AC6B, #F4D187, #FFFFC2);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent; */
   color:#D7AC6B ;
   font-size: 50px;
   font-family: 'Montserrat', sans-serif;
   font-weight:700;
   text-transform: uppercase;
   line-height: 1;
   margin: 0 0 10px;
}

.prodImg {
  position: absolute;
  right: 14%;
  max-width: 200px;
  bottom: 37%;
}

.nominationShareBtn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.shareProdDiv {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 20px;
  width: auto;
}


/* winner 2022 pages css starts */

.winnerCategory{
  padding-bottom: 100px;
  height: 100%;
}

.winnerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.winnerColumn {
  width: 32%;
  text-align: center;
  margin-top: 50px;
}

.winnerColumn img {
  width: 90%;
}

.winnerColumn h4 {
  margin: 20px 0;
  /* color: #fff; */
}

.juryVideoSec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.juryVideoSec video {
  position: relative;
}

.cstm_multi-slct {
  background-color: #fff;
 box-shadow: 0 2px 10px #777;
}

.cstm_multi-slct .MuiSelect-select {
 padding: 14px 10px !important;
}

.cstm_multi-slct fieldset {
    border-color: rgba(0, 0, 0, 0.87) !important;
    border-radius: 5px;
 }

 .multi_label {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #000 !important;
  font-size: 14px !important;
  background: #fff;
}

.multi_label.MuiInputLabel-shrink.Mui-focused {
  padding: 0 6px;
  background: #fff;
}

.text_red {
  color: red;
}

.unselct_categry {
  position: relative;
  top: -20px;
  font-size: 14px;
}

.interest_modal {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  text-align: center;
}

.interest_modal img {
  max-width: 100px;
}

/* ================================== Glsss blur classes ==============================================*/
 .glass_blur_container {
   background: rgba(0, 0, 0, 0.8);
background-color: rgba(255, 255, 255, .15);
  border-radius: 5px;
  padding: 30px;
}
@media screen and (max-width: 425px) {
  .glass_blur_container {
      margin: 15px;
      padding: 15px;
  }
}

/* ===============info card============================ */
.infoCard_title h2{
  font-size:16px;
}
.infoCard_description p{
  font-size:14px;
}

.infoCard_title h2, .infoCard_description p {
  color: #000000;
}

.bg-brown .infoCard_title h2, .bg-brown .infoCard_description p,
.bg-black .infoCard_title h2, .bg-black .infoCard_description p 
{
  color: #FFFFFF;
}


/* award category page css */

section .container-fluid {
  padding: 0 35px;
}

.categorySlide {
  background: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  text-align: center;
  width: 100%;
}

.criteria_title_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.categorySlide h3 {
  font-size: 20px;
  font-weight: 600;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.swiper-slide .categorySlide img {
  max-height: 170px;
  object-fit: contain;
  width: auto !important;
  height: 100% !important;
}

.guidelines ul li {
  font-size: 17px;
  font-weight: 400;
}

.criteria ul {
  background: #222222;
  padding: 50px;
  border-radius: 10px;
}

.criteria ul li {
  color: #fff;
  font-size: 20px;
  font-family: 'PT Serif', serif;
  margin-bottom: 50px;
  line-height: 2;
}

.judging_criteria_slide .criteria ul li {
  margin-bottom: 20px;
}

.criteria ul li:last-child{
  margin: 0;
}

.judging_criteria_slide{
  width: 100% !important;
}

.swiper_outer {
  position: relative;
}

.customArrow {
  position: absolute;
  right: 0;
  top: -60px;
}

.prv_btn {
  background: #000000;
  color: #fff;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.nxt_btn {
  background: #000000;
  color: #fff;
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.inner_winner_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items:center;
}

.winners_years {
  width: 36%;
  text-align: center;
}


.winners_years span {
  color: #FAB900;
  font-size: 35px;
  margin: 0 35px;
}


.winnerTitle h4 {
  color: #FAB900;
  font-size: 28px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 40px;
}

.inner_winner_title h1 {
  font-size: 85px;
  font-weight: bold;
  position: relative;
}


.active_year {
  position: absolute;
  right: -80px;
  background: #FAB900;
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 500;
  color: #161006;
  border-radius: 5px;
  padding: 10px 25px;
  transform: rotate(-12deg);
  top: 45px;
}

.cstom-dropdown .dropdown-item.active, .cstom-dropdown  .dropdown-item:active {
 background: var(--brown);
}


/* =======  New sections css starts from here 2023 =======*/

/* banner slider css */

.banner_slider {
  text-align: center;
  /* padding: 20px 0 80px; */
}

.home_top_slide {
  padding: 20px 0 80px;
}

.home_top_slide img {
  width: 100%;
  position: relative;
  max-height: 720px;
  object-fit: contain;
}

.banner_slider .swiper-pagination-bullet {
  width: 30px;
  height: 8px;
  border-radius: 0;
  opacity: 1;
  background: #fff;
}

.swiper-button-prev , .swiper-button-next{
  color: var(--brown) !important;
}

.banner_slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--brown);
}

.second_banner_slide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  height: 750px;
  padding-right:50px;
}

.slider_bg {
  background: url(../img/new/banner_bg.png);
  background-size: cover;
  background-position: center center;
}

.second_banner_slide img {
  width: 60%;
  max-height: inherit;
}

.top_banner_content h3 {
  font-weight: 600;
  color: #0D0E10;
  font-size: 30px;
}

.top_banner_content p {
  background: #000;
  color: #fff;
  display: inline-block;
  padding: 10px 20px;
  margin: 20px 0 0;
  font-size: 20px;
}

/* ==== Awards highlights section css===== */

.previousYear {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 70px;
}

.awards_highlights_video {
  width: 33%;
  text-align: center;
}

.awards_highlights_video h3 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}


/*=== timeline section css ==== */

.timeline_outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 650px;
  padding: 0 35px;
}

.time_line_rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: max-content;
  position: relative;
}

.time_line_first_row{
  padding: 0 0px 40px;
  justify-content: space-between;
}

.time_line_third_row{
  padding:40px 0 0;
  justify-content: space-between;
}

.circle_item_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
}

.circle_fill_item {
  position: relative;
  height: 110px;
  width: 110px;
  background-color: #FFF5EC;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 35px;
  font-family: 'PT Serif';
  line-height: 1;
  margin: 0;
  z-index: 1;
}

.time_line_first_row .circle_fill_item {
  top: -60px;
}

.time_line_third_row .circle_fill_item {
  top: 60px;
}


.timeline_vertical_line {
  position: absolute;
  height: 80px;
  background: #FFF5EC;
  width: 2px;
  bottom: 30px;
  z-index: 0;
}

.timeline_content {
  width: 20%;
}

.time_line_third_row .timeline_vertical_line {
  bottom: inherit;
  top: 40px;
}

.Timeline_desc_title {
  color: #FFF5EC;
  font-family: 'PT Serif';
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 5px;
}

.Timeline_desc_text{
  color: #FFF5EC;
}

.timeline_progress_bar{
  height: 47px;
  width: 20%;
  border: none;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
  
.timeline_progress_bar_1{
  background-color: #FFF5EC;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.timeline_progress_bar_2{
  background-color: #EEDAA8;
}

.timeline_progress_bar_3{
  background-color: #E8C059;
}

.timeline_progress_bar_4{
  background-color: #D2980E;
}

.timeline_progress_bar_5{
  background-color: #F7B824;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}


.Timeline_desc_text_2{
  color: #FFF5EC;
}

.Timeline_desc_title_2{
  color: #FFF5EC;
}


.Timeline_desc_text_3{
  color: #E8C059;
}


.Timeline_desc_title_4{
  color: #E8C059;
}

.Timeline_desc_title_5{
  color: #F7B824;
}

.timeline_item_second{
  background: #EEDAA8 !important;
}

.timeline_item_third{
  background: #E8C059 !important;
}

.timeline_item_fourth {
  background: #D2980E !important;
}

.timeline_item_fifth{
  background: #F7B824 !important;
}

.item_item_4th_text{
  color: #E8C059;
}

.timeline_date {
  color: #fff;
  font-family: 'PT Serif';
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 25px;
}

.timeline_progress_bar_triangle{
  position: absolute;
  height: 33px;
  width: 33px;
  rotate: 45deg;
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline_progress_bar_triangle_1{
  top: 30px;
  background-color: #FFF5EC;
}

.timeline_progress_bar_triangle_2{
  top: -17px;
  background-color: #EEDAA8;
}

.timeline_progress_bar_triangle_3{
  top: 30px;
  background-color: #E8C059;
}

.timeline_progress_bar_triangle_4{
  top: -17px;
  background-color: #D2980E;
}

.timeline_progress_bar_triangle_5{
  top: 30px;
  background-color: #F7B824;
}


/* === gallery section css === */
.gallery_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.view_all_link {
  color: #F7B824 !important;
  font-size: 20px;
  text-decoration: underline;
  font-weight: 600;
}

.gallery_images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.gallery_image_container {
  width: calc(100% / 3 - 27px);
}

.gallery_image{
  width: 100%;
  border-radius: 10px;
  position: relative;
}


/* =============== gallery_section =================== */
.gallery_container_page {
  width: 100%;
}

.gallery_headers {
  padding: 60px 32px;
}

.Gallery_Slide {
  margin-bottom: 20px;
}

.Gallery_Slide img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  border-radius: 5px;
}

.Gallery_Slide {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  width: auto !important;
}


/* winners 2023 new section */

.winners2023_section {
  width: 100%;
  height: max-content;

}

.sub_header_winners {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  margin-bottom: 30px;
}

.sub_header_winners p {
 font-size: 30px;
 font-weight: 600;
 margin: 0;
}


.winners2023_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.winners2023_image_container {
  width: 140px;
  height: 182px;
  background-color: #FFFFFF;
  border: 4px solid #FAB900;
  border-radius: 11.375px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* ==== Pr Section start here === */
.pr_sec .container-fluid {
  padding-right: 0;
}

.pr_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 70px 0;
}

.pr_container_left {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 50%;
}

.pr_container_right {
  width: 50%;
}

.pr_container_left h3 {
  font-weight: 600;
}

.pr_container_left h6 {
  font-size: 18px;
}

.pr_sec {
  position: relative;
}

.pr_background_container {
  position: absolute;
  height: 90%;
  right: 0;
  width: 33%;
  background-color: #FAB900;
  z-index: -1;
  top: 7%;
  bottom: 0;
}

.pr_main_image {
  width: 80%;
}


/* === social media css start heres ==== */

.social_media_image_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #222222;
  border-radius: 5px;
  padding: 30px;
}

.social_media_image {
  width: 100%;
}


/* ====== Testimonials section css starts ======= */

.testimonial_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.testimonial_swiper_buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.testimonial_buttons{
  height: 48px;
  width: 48px;
  background: #000000;
  border: none;
  border-radius: 50%;
  outline: none;
}

.Testimonial_slide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
  width: 100%;
}

.testimonials_left{
  position: relative;
  width: 30%;
}


.testimonial_brand_image {
  position: absolute;
  height: 70px !important;
  width: 70px !important;
  border-radius: 37px;
  bottom: 20px;
  right: 20px;
}

.testimonial_user_image {
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 3px;
  width: 100%;
}


.testimonials_back_ground {
  height: 180px;
  width: 175px;
  background: #FAB900;
  border-radius: 3px;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}

.testimonials_right{
  border-top: 1px solid #FAB900;
  padding: 35px 0;
  width: 100%;
}

.award_image > img{
  height: 150px;
  width: 150px;
  margin-bottom: 30px;
}

.testimonials_description {
  margin-bottom: 20px;
  width: 100%;
}

.testimonials_description p {
  /* text-indent: 10px; */
  font-size: 21px;
  line-height: 1.8;
  margin-bottom: 0;
}

.testimonial_author {
  margin-bottom: 30px;
}

.testimonial_author > h5 {
  font-size: 18px;
  color: #161006;
  padding-left: 30px;
  position: relative;
}

.testimonial_author > h5::before {
  content: '';
  width: 25px;
  height: 1px;
  background: #161006;
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
}

.bottom_slide_navigation{
  border-top: 1px solid #FAB900;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dot_pointers{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.bottom_nav_btn {
  background: rgba(165, 164, 163, 0.5);
  border-radius: 20px;
  border: none;
  width: 10px;
  height: 12px;
}
  
.active_botton_dots{
  background: #FAB900;
}

.next_slide_button{
  background: none;
  outline: none;
  border: none;
}

/* ======inner pages css ========== */

/* =========== contact page =========== */
/* .contact_container {
  width: 100%;
  min-height: 100vh;
}

.contact_container_box {
  display: flex;
  flex-wrap: wrap;
} */

/* ===== how-to-participate page ========= */

.how_to_nominate_list{
  margin-bottom: 60px;
}

.how_to_nominate_list li {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.how_to_container_box {
  background-color: #222222;
  border-radius: 5px;
  padding: 30px;
}

.key_point_number{
  position: relative;
}

.how_to_container_box_elements .key_point_number::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 50px;
  background-color: #F7B824;
  top: 25px;
  left: 15px;
}

.last_process_element:before {
  content: none !important;
}

/* ======= faq page css ====== */

.faq_container{
  height: max-content;
}


.faq_container_box{
  align-items: start;
  justify-content: start;
  background-color: #222222;
  border-radius: 5px;
  padding: 30px;
}

/* ===== infoCard component css here === */

.infoCard_description > p {
  color: #000000;
}

.InfoCard_main {
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
  position: relative;
  padding-left: 12px;
}

.infoCard_container{
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.infoCard_title > h2{
    position: relative;
    font-weight: 700;
    font-size: 17px;
}

.title_wall {
	height: 24px;
	width: 4px;
	background: #D2980E;
	position: absolute;
	left: 0;
}

.infoCard_description > p {
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
}

/* ===== keypoint component css here ========= */

.key_point_container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 15px;
	width: 100%;
	margin-bottom: 25px;
}

.key_point_container:last-child {
    margin: 0;
}

.key_point_number{
    font-family: 'PT Serif';
    width: 30px;
    height: 30px;
    background: #F7B824;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}

.key_point_details_point{
    width: 90%;
}

.key_point_title{
    font-family: 'PT Serif';;
    font-weight: 700;
    font-size: 17px;
    margin-bottom:0px;
}

.key_point_desc{
    font-weight: 400;
    font-size: 14px;
    margin-bottom:0px;
}

/* ====== why participate page csss  start here ===  */

.why_participate_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.whatsForYour_2By2_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  gap: 100px;
  background: #222222;
  padding: 60px 30px;
  border-radius: 5px;
}

.whatsForYour_2By2_container .InfoCard_main {
  width: 35%;
}

/* === policy page === */

.policy_container_box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	gap: 60px;
	flex-wrap: wrap;
	margin-bottom: 50px;
  background: #222222;
  padding:60px 30px;
}

/* ==== evaluation page csss ======= */

.evaluation_container_box{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.evaluation_tab_navigation{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  height: 100%;
  align-items: flex-start;
}

.evaluation_tab_btns{
  z-index: 1;
  width: 90%;
  border: none;
  background-color: transparent;
  /* height: 50px; */
  padding: 10px 20px;
  border-radius: 8px;
  font-family: 'PT Serif';
  font-weight: 700;
  font-size: 16px;
  text-align: start;
}

.evaluation_tab_btns_active{
  background: #FAB900;   
  transition: all 0.3s ease-in-out;
}

.evaluation_tab_btns:hover{
  background: #FAB900;
  transition: all 0.3s ease-in-out;
}


.evaluation_tab_data{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  height: 100%;
  flex-wrap: wrap;
}


.evaluation_tab_data .InfoCard_main {
  width: 47%;
}




/*===== jury inner page ====== */
.Jury_Slide {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  width: auto !important;
}

.Jury_Slide {
  margin-bottom: 20px;
}

.jury_slider_card{
  position: relative;
  cursor: pointer;
}

.jury_slider_company {
  position: absolute;
  height: 50px;
  width: 50px;
  z-index: 0;
  top: 16px;
  left: 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(21, 27, 38, 0.08);
  border-radius: 10px;
  object-fit: contain;
  padding: 2px;
}


.jury_slider_main_image {
  width: 262px;
  height: 262px;
  object-fit: contain;
  border-radius: 14px;
}

.jury_slider_name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  padding: 5px 8px;
  background: rgba(0,0,0,0.5);
  border-radius: 0 0 12px 12px;
}

.jury_name_text {
  margin: 0;
  font-weight: 600;
}

.jury_designation {
  font-size: 14px;
  line-height: 1.4;
}

.jury_name_text , .jury_designation{
  margin: 0;
  color: #fff;
}


/* jury slider homepage 2023 */

.juryhome_slider {
  padding: 100px 0;
}

.prv_btn, .nxt_btn {
  border: 1px solid #fff;
}

.jury_image {
  width: 75%;
  border-radius: 50%;
}

.jury_image_outer {
  position: relative;
}

.jury_slide_card {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.brand_logo_outer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  border-radius: 50%;
  height: 100px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jury_company_image {
  object-fit: cover;
  max-height: 70px;
  max-width: 70px;
  border-radius: 50px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.jury_content_details {
  background: rgba(185, 134, 38, 0.45);
  margin-top: 20px;
  padding: 15px 10px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.jury_slide_card::after {
  content: "";
  border-left: 152px solid rgba(185, 134, 38, 0.45);
  border-bottom: 25px solid transparent;
  display: block;
  border-right: 152px solid rgba(185, 134, 38, 0.45);
  border-radius: 0 0 7px 7px;
}

.jury_content_details p {
  margin: 0;
  color: #fff;
}

.jury_content_details h5 {
  font-weight: 700;
  color: #fff;
  font-family: "poppins", sans-serif;
}



.scndry_heading {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.live_event {
  background: url(../img/live_event_bg.png);
  background-size:cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 20px;
  text-align: center;
}

.live_event img {
  width: 300px;
  margin-bottom: 20px;
}
.live_event h1 {
 color: #fff;
 font-size: 40px;
 font-family: "poppins", sans-serif;
 font-weight: 700;
}
.live_event p {
  font-family: "poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
 color: #fff;
 margin-bottom: 30px;
}
.live_event h2 {
  font-family: "poppins", sans-serif;
  font-weight: 600;
  color: #FFC400;
  font-size: 36px;
}


/*===================================responsive start here================================================*/

@media screen and (min-width: 1920px) {
  /* For Projectors or Higher Resolution Screens (Full HD) */
}

@media screen and (min-width: 3840px) {
  /* //For 4K Displays (Ultra HD) */
}

/*============================= Tablet landscape size to standard 960  =============================*/
@media only screen and (min-width: 959px) and (max-width: 1024px) {
  .nominee_workflow {
    padding-left: 50px;
  }

  .footer_form {
      width: 400px;
      height: 400px;
  }

  img.rotataImg {
    width: 98%;
  }

  .pd-ryt {
    padding-right: 0px;
  }

  .pd-lft {
    padding-left: 0px;
  }
  
}

/* ============================= global tablet and mobile =============================*/
@media only screen and (max-width: 959px) {
  .main {
    padding-left: 0px;
    /* padding-bottom: 70px; */
  }

  
  #mainId {
    position: relative;
    display: inline-block;
  }

  section {
    height: 100%;
  }

  .rightsidebar .navbar-brand {
    display: block;
  }

  .navbar-light .navbar-toggler {
    border: 0;
    padding: 0;
    box-shadow: none;
  }

  .sidebar_logo {
    display: none;
    z-index: 1;
  }

  .sidebar {
    width: 100%;
    height: 70px;
    bottom: 0;
    top: inherit;
    padding: 4px;
    z-index: 9;
  }

  .sidebar .nav-item {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    width: 100%;
  }

  .sidebar .nav-item .nav-link {
    padding: 7px 10px;
    height: 100%;
  }

  .sidebar span svg {
    font-size: 15px;
  }

  .sidebar span {
    font-size: 12px;
  }

  .sidebar .nav-item .nav-link.active,
  .sidebar .nav-item .nav-link:hover,
  .sidebar .nav-item .nav-link {
    border: 0;
    border-radius: 10px;
  }

  .homepage .header {
    position: relative;
    padding: 10px 15px;
  }

  
  .header.is-sticky{
    position: fixed;
    background: #fff;
   
  }

  .personal_details {
    width: 100%;
  }

  .custom_toaster {
    z-index: 9;
  }

  body .header .nav-link {
    color: var(--black) !important;
  }

  body .header.is-sticky .text-black .nav-link {
    color: var(--black) !important;
  }

  .pd-ryt {
    padding: 0 15px;
  }

  .pd-lft {
    padding: 0px;
  }

  .about_img img {
    animation: none;
    width: 100%;
    margin-bottom: 20px;
  }

  .mob_ordr_chng {
    order: 2;
    /* margin-top: 20px; */
  }

  img.Logo {
    max-width: 220px;
  }


  .previousYear iframe {
    width: 100%;
  }
  
  .footer_section .container-fluid {
    padding: 0 15px;
  }

  section .container-fluid, .section_title {
    padding: 0 15px;
  }

  .evaluation_tab_data {
    gap: 15px;
  }

  .policy_container_box {
    gap: 15px;
    padding: 15px;
  }
  





}

/* ================Tablet Portrait size to standard 960 (devices and browsers) ================*/
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .loginDiv {
    width: 65%;
    margin: 60px auto;
  }

  .nominee_workflow {
    padding-left: 20px;
  }

  .title_div h6 {
    margin-right: 30px;
  }

  .title_div h4 {
    font-size: 18px;
  }

  .tab_inner_div {
    width: 80%;
    margin: 0 auto;
  }
  .social_links {
    margin-bottom: 20px;
  }

  #v0 {
    height: 82%;
  }

  .previousYear {
    margin-top: 10px;
  }

  .previousYear iframe {
    width: 100%;
  }

  video.testimonial_video {
    margin: 50px 0;
  }

  .testimonial_box .testi_video_div {
    width: 80%;
    height: 80%;
    transform: rotateY(calc(var(--my-css-var) * 60deg)) translateZ(250px);
  }

  .circle_fill_item {
    width: 75px;
    height: 75px;
  }

  .winners2023_image_container {
    width: 22.2%;
  }

  .section_title h2 {
    font-size: 28px;
  }

  .how_to_container_box_elements .key_point_number::before {
    height: 70px;
  }

  .how_to_container_box {
    padding: 20px;
  }

  .InfoCard_main {
    width: 44% !important;
  }

  .whatsForYour_2By2_container {
    gap: 20px;
  }

  .homeModal .modal-dialog {
    max-width: 95%;
  }







}

/* ==================All Mobile Sizes (devices and browser) ==================*/

@media only screen and (max-width: 767px) {
  .nominee_workflow {
    padding-left: 15px;
    padding-right: 15px;
  }

  .custom_toaster {
    width: 100%;
    z-index: 9;
  }

  .cart_details {
    display: inline-table;
  }

  .inner_title {
    margin: 15px 0;
  }

  .loginDiv {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }

  .cart_inner,
  .cart_table {
    padding: 10px;
  }

  .title_div {
    display: inline-block;
  }

  .tab_inner_div {
    width: 100%;
    margin: 0 auto;
  }

  .form_row .form-check {
    padding-left: 25px;
  }

  .radio_input input[type='text'] {
    padding-left: 5px;
    margin-left: 0;
  }

  .radio_input .form-check {
    min-width: 165px;
  }

  section {
    padding: 40px 0;
    height: 100%;
  }

  section .container-fluid {
    padding: 0 15px;
  }

  section.testimonial_section {
    height: 100%;
  }

  .testimonial_box .testi_video_div {
    width: 50%;
    height: 50%;
    transform: rotateY(calc(var(--my-css-var)  * 60deg)) translateZ(150px);
  }


  .text2021 {
    font-size: 40px;
  }

  .leaf_img {
    width: 100%;
    margin: 0 auto;
  }

  .homepage .header {
    margin-bottom: 0;
  }

  .inner_about {
    padding: 50px 0;
  }

  .section_title {
    padding: 0 15px;
  }

  .about_txt h3 {
    margin-bottom: 10px !important;
  }

  .about_img img {
    width: 100%;
  }

  .mob_mar_2 {
    margin-bottom: 20px;
  }

  img.rotataImg {
    width: 100%;
    animation: spin 20s linear infinite;
  }

  /* .footer_form_div {
    padding: 35px 15px;
  } */

  .footer_details {
    padding: 35px 15px 5px 20px;
  }

  .footer_links {
    margin: 55px 0px 0 0px;
  }

  .footer_bottom {
    padding: 35px 15px 0 15px;
  }

  .social_links {
    margin: 10px 0;
  }

  .social_connect p {
    text-align: center;
  }

  .social_links ul {
    margin: 0 auto;
    text-align: center;
  }

  .form_inner .form-floating > .form-control {
    height: 30px;
  }
  .form_inner .form-floating > label {
    padding: 4px 12px;
  }
  .sliderHeading h4 {
    font-size: 20px;
  }

  .section_title h2 {
    font-size: 25px;
  }

  .social_connect h5 {
    text-align: center;
  }

  .header{
    padding: 20px 15px;
  }

  .mobilebgHeight {
    height: 105vh;
    overflow: auto;
    position: relative;
    display: block;
  }

  .mobileBanner {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  #v0 {
    display: none;
  }

  #set-height {
    height: auto !important;
    display: none !important;
  }
 
  .mainContainer {
    display:  block !important;
    margin-top: 0;
  }

  .loader {
    display: none;
  }

  .social_connect h4 {
    text-align: center;
  }

  .social_links li {
    margin-bottom: 10px;
  }

  .countdown h4 {
    font-size: 30px;
  }

  .smalltext {
    font-size: 20px;
    margin-top: 10px;
  }

  #clockdiv div span {
    font-size: 30px;
    width: auto;
    padding: 10px;
    margin-right: 5px;
  }

  #clockdiv > div {
    margin-right: 10px;
  }

  .votingSlider h3 {
    font-size: 25px;
  }

  .votingSlider h3 a {
    text-align: right;
  }

  .voteTitle h4 img {
    top: 4px;
    max-width: 10px;
  }

  .votemodalBody .voteTitle h4 img {
    left: -15px;
  }

  .voteTitle h4 {
    font-size: 20px;
  }

  .voteButton p {
    font-size: 20px;
  }
  .productImg {
    width: 47%;
  }

  .voterLogin {
    width: 100%;
    margin: 0 auto;
 }

 .registerNow {
  bottom: 10px;
 }

 /* .registerNow img {
  display: none;
 } */

 .RegisterTop button {
    font-size: 20px;
    padding: 6px 15px;
  }

  .previousYear  .d-flex {
    flex-direction: column;
  }

  .previousYear iframe {
    margin-bottom: 15px;
  }

  .criteria ul {
    padding: 20px;
  }

  .criteria ul li {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
  }


  /* css for new design */

  .scrollArrow {
    display: none;
  }

  .home_top_slide img {
    width: 100%;
    height: auto !important;
    object-fit: cover !important;
    padding: 35px;
  }

  .swiper-button-prev::after, .swiper-button-next::after {
    font-size: 25px !important;
  }

  .awards_highlights_video h3 {
    font-size: 20px;
  }

  .previousYear {
    flex-direction: column;
    margin-top: 0;
  }

  .awards_highlights_video {
    width: 100%;
  }

  .benefitSec {
    height: 100%;
  }

  .gallery_images {
    gap: 15px;
  }

  .gallery_image_container {
    width: 100%;
  }

  .sub_header_winners {
    padding:0 15px;
  }

  .sub_header_winners p {
    font-size: 18px;
  }

  .view_all_link {
    font-size: 15px;
  }

  .timeline_outer {
    flex-direction: row;
    padding: 0 15px;
  }

  .pr_container{
    flex-direction: column;
    padding: 0;
  }

  .pr_container_left {
    gap: 10px;
    width: 100%;
  }

  .pr_container_right {
    width: 100%;
  }

  .pr_background_container {
    display: none;
    /* height: 65%;
    right: 0;
    width: 100%;
    top: inherit;
    bottom: -12px;
    left: -11px; */
  }

  .pr_sec .container-fluid {
    padding: 0 15px;
  }

  .pr_main_image {
    width: 100%;
  }

  .social_media_image_container {
    padding: 10px;
  }

  .Testimonial_slide {
    flex-direction: column;
    gap: 20px;
  }

  .testimonials_left, .testimonials_right, .testimonials_description {
    width: 100%;
  }

  .award_image > img {
    margin-bottom: 10px;
  }

  .time_line_rows {
    flex-direction: column;
    width: 33%;
  }

  .circle_item_container, .timeline_content {
    width: 100%;
    height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }


  .timeline_progress_bar {
    height: 225px;
    width: 40px;
  }

  .circle_fill_item {
    height: 40px;
    width: 40px;
    font-size: 25px;
  }

  .timeline_progress_bar_1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
  }

  .timeline_progress_bar_5 {
    border-bottom-right-radius:10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius:0
  }

  .timeline_progress_bar_triangle {
    width: 25px;
    height: 25px;
    top: 45% !important;
  }

  .timeline_progress_bar_triangle_1 , .timeline_progress_bar_triangle_3, .timeline_progress_bar_triangle_5{
    right: -40px;
  }

  .timeline_progress_bar_triangle_2, .timeline_progress_bar_triangle_4 {
    top: 40px;
    left: -40px;
  }

  .Timeline_desc_title {
    font-size: 15px;
  }

  .timeline_date {
    font-size: 17px;
  }

  .time_line_first_row .circle_fill_item {
    top: 0;
  }

  .time_line_third_row .circle_fill_item {
    top: 0;
  }

  .Timeline_desc_text {
    font-size: 14px;
  }

  .timeline_vertical_line {
    display: none;
  }

  .prv_btn, .nxt_btn {
    width: 30px;
    height: 30px;
  }

  .how_to_container_box{
    padding: 15px;
  }

  .InfoCard_main {
    width: 100% !important;
  }

  .faq_container_box {
    padding: 15px;
  }

  .whatsForYour_2By2_container {
    gap: 20px;
  }

  .how_to_container_box_elements .key_point_number::before { 
    content: none;
  }

  .evaluation_tab_btns {
    padding: 5px;
  }


  .second_banner_slide {
    flex-direction: column;
    padding: 10px;
  }

  .top_banner_content h3 {
    font-size: 15px;
  }

  .top_banner_content p {
    padding: 5px 10px;
    margin: 10px 0 0;
    font-size: 15px;
  }

  .banner_slider {
    padding: 0;
  }

 
  .Gallery_Slide img {
      width: 100%;
      height: 200px;
  }

  .nomination_modal {
    padding: 20px;
  }

  .homeModal .btn-close {
    right: 15px;
  }

  .homeModal .modal-dialog {
    max-width: 100%;
  }

  .homeModal .modal-header p {
    font-size: 17px;
    text-align: center;
  }

  .homeModal .modal-header {
    padding-right: 50px;
  }

  .nomination_row {
    flex-direction: column;
    padding: 0;
  }

  .nomination_modal_catgry, .nomination_modal_content {
    width: 100%;
  }

  .nomination_modal_content h3, .nomination_modal .registerNow {
    margin-top: 15px;
  }

  .nomination_modal_catgry h1 {
    font-size: 45px;
  }

  .nomination_modal_content .form_row {
    width: 85%;
  }


 

  

 


  

    

}

/* ===================Mobile Landscape Size to Tablet Portrait (devices and browserss)=================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {

  .social_media_image {
    width: 50%;
  }

}


/* =====================Mobile Portrait Size to Mobile Landscape Size (devices and browsers)===================== */

@media screen and (max-width: 479px) {
  .textHeading {
    font-size: 30px;
  }

  .footer_form {
    width: 275px;
    height: 275px;
    padding: 0;
  }

  .form_inner .form-floating.mb-4 {
    margin: 0 auto 10px !important;
    width: 80%;
  }

  .arrowBottom {
    bottom: 35% !important;
  }

  .companyVote {
    width: 100%;
    margin:0 0 10px;
  }

  .winnerRow {
    flex-direction: column;
    justify-content: flex-start;
  }

  .winnerColumn {
    width: 100%;
    margin-top: 10px;
  }

  .winnerColumn img {
    width: 60%;
  }

  .winners2023_image_container {
    width: calc(100% / 2 - 12px);
  }

  .social_media_image {
    width: 100%;
  }

  .award2023 video {
    height: 400px;
    object-fit: cover;
  }

  .section_title h2 {
    font-size: 22px;
  }

  .second_banner_slide {
    height: 370px;
  }

  .second_banner_slide {
    height: 370px;
  }

  .Gallery_Slide img {
    width: 100%;
    height: 160px;
  }
}
