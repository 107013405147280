.winnersInner {
  max-height: 90vh;
  min-height: 90vh;
  width: 100%;
  position: relative;
  padding: 0;

  display: flex;
}
.winnersInner > div {
  /* flex: 1; */
  height: 90vh;
}
.winnersInner > div:first-child {
  flex: 0.3;
}
.winnersInner > div:last-child {
  flex: 1;
}
.winnersInner h1 {
  font-size: 2.5rem;
  font-weight: 400;
  position: absolute;
  top: 10vh;
  left: 10vh;
}
.card2021 {
  height: 40vh;
  width: 24vh;
  background-color: #2a9d8e;
  display: grid;
  place-items: center;
  border-radius: 20vh;
  font-size: 12vh;
  line-height: 12vh;
  font-weight: 600;
  color: white;
  position: absolute;
  top: 30%;
  left: 10%;
  transform: translateY(-50%);
  cursor: pointer;
  text-align: center;
}

.card2020 {
  height: 26vh;
  width: 16vh;
  background-color: #f3a261;
  display: grid;
  place-items: center;
  border-radius: 20vh;
  font-size: 8vh;
  line-height: 8vh;
  font-weight: 600;
  color: white;
  position: absolute;
  top: 38%;
  left: 21%;
  transform: translateY(-50%) rotate(11.5deg);
  cursor: pointer;
}
.cardAbove {
  z-index: 10;
}
.cardBelow {
  z-index: 5;
}
.cardAbove::after {
  content: '';
  height: 100%;
  width: 100%;
  /* background-color: black; */
  border-radius: 30vh;
  border: 2px solid white;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(3deg) scale(1.1);
  z-index: 10;
}
.winnerProductsMain {
  height: 100%;
  /* background-color: #fff !important; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 50%;
}
.innitialFront {
  --size: 50vh;
  min-height: var(--size);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translate(-50%, 0);
  transform-origin: bottom;
  transition: 0.8s ease-in-out;
}
.winnerProductsMain > .innitialFront img:first-child {
  height: calc(var(--size) * 0.6);
  transform: translateY(calc(var(--size) * 0.1));
}
.winnerProductsMain > .innitialFront img:last-child {
  height: calc(var(--size) * 0.36);
}
.atFront {
  animation: cycleThroughWinnersFront 0.6s ease-in forwards;
  /* z-index: 10; */
}
@keyframes cycleThroughWinnersFront {
  0% {
    transform: translate(20%, -25%);
    z-index: 10;
  }
  85% {
    z-index: 12;
  }
  100% {
    z-index: 12;
    transform: translate(-50%, 0%);
  }
}
.atLeftBack {
  animation: cycleThroughWinnersLeftBack 0.6s ease-in forwards;
  z-index: 11;
}
@keyframes cycleThroughWinnersLeftBack {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-120%, -25%);
  }
}
.atRightBack {
  animation: cycleThroughWinnersRightBack 0.6s ease-in forwards;
  z-index: 10;
}
@keyframes cycleThroughWinnersRightBack {
  0% {
    transform: translate(-120%, -25%);
  }
  100% {
    transform: translate(20%, -25%);
  }
}

.winnerSliderContainer {
  position: relative;
}
.winnerSliderContainer > section {
  min-width: 60%;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
}
.winnerSliderContainer > section img {
  height: 8.6vh;
  cursor: pointer;
  transition: 0.05s;
}
.winnerSliderContainer > section img:active {
  transform: scale(1, 0.7);
}
.winnerSliderContainer > section h4 {
  font-size: 5vh;
  text-align: center;
  margin-top: 1.2vh;
}
.prevWinner {
  transform: translateX(-50vw);
  opacity: 0;
}
.nextWinner {
  transform: translateX(50vw);
  opacity: 0;
}

.arrowBottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 110px;
  left: 0;
  right: 0;
}

.arrowBottom img {
  max-width: 30px;
  cursor: pointer;
}

.arrowBottom h4 {
  margin: 0 30px;
}
/* 
.fadeInUp_anime {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  position: relative;
  top: 30px;
}


@keyframes fadeInUp {
      0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
} */

.winnerpage.homepage .header {
  display: flex;
}

.winnerpage .header {
  background: rgba(33 ,14 ,10 ,0.8);
}
.winnerpage .header .nav-link{
  color: #fff !important;
}


.winnerpage.homepage .header .nav-link{
 color: #fff !important;
}

.winnerpage .sidebar {
  display: none;
}

.winnerpage .navbar-brand {
  display: block;
}

.winnerpage .navbar-brand img {
  max-width: 200px;
}


@media only screen and (min-width: 768px) and (max-width: 959px) {
  .winnersInner > div {
    height: auto;
  }
  
  .arrowBottom {
    bottom: 55px;
  }

}




@media all and (max-width: 959px) {
  .winnersInner {
    max-height: 70vh;
    min-height: 70vh;
  }
  .winnerSliderContainer > section {
    bottom: 20%;
  }
  .winnerSliderContainer > section h4 {
    font-size: 2.6vh;
  }
  .innitialFront {
    --size: 50vw !important;
  }
  .mpt-0 {
    padding-top: 0;
   }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .innitialFront {
    --size: 20vw !important;
  }

  .winnerSec {
    padding: 10px 0;
  }

  .arrowBottom {
    bottom: 100px;
  }

}

@media screen and (max-width: 479px) {

}
